/*Badges Styles*/
.badge {
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  margin-bottom: 6px;
  @include border-radius($border-radius-sm);

  &:not(:last-child) {
    margin-right: 15px;
  }

  table & {
    margin-bottom: 0;
  }
}
#survey-form .badge {
  position: absolute;
  top: 50%;
}
.badge-pill {
  @include border-radius(20px);
  padding: .575rem 1.85rem;
  font-size: 17px;
  line-height: 1.5;
}

.badge-border {
  border: 2px solid !important;
}

.badge-outline {
  background-color: transparent;
  border: $jr-border;
}