
.rc-heart > .rc-rate-star-half .rc-rate-star-first,
.rc-heart > .rc-rate-star-full .rc-rate-star-second {
	color: #fd949b;
}
.rc-heart > .rc-rate-star-half:hover .rc-rate-star-first,
.rc-heart > .rc-rate-star-full:hover .rc-rate-star-second {
	color: #fd949b;
}

.rc-mind > .rc-rate-star-half .rc-rate-star-first,
.rc-mind > .rc-rate-star-full .rc-rate-star-second {
	color: #89bcf8;
}
.rc-mind > .rc-rate-star-half:hover .rc-rate-star-first,
.rc-mind > .rc-rate-star-full:hover .rc-rate-star-second {
	color: #89bcf8;
}

.rc-body > .rc-rate-star-half .rc-rate-star-first,
.rc-body > .rc-rate-star-full .rc-rate-star-second {
	color: #63D4D0;
}
.rc-body > .rc-rate-star-half:hover .rc-rate-star-first,
.rc-body > .rc-rate-star-full:hover .rc-rate-star-second {
	color: #63D4D0;
}

.rc-spirit > .rc-rate-star-half .rc-rate-star-first,
.rc-spirit > .rc-rate-star-full .rc-rate-star-second {
	color: #c98fff;
}
.rc-spirit > .rc-rate-star-half:hover .rc-rate-star-first,
.rc-spirit > .rc-rate-star-full:hover .rc-rate-star-second {
	color: #c98fff;
}

.rc-recovery > .rc-rate-star-half .rc-rate-star-first,
.rc-recovery > .rc-rate-star-full .rc-rate-star-second {
	color: #63D4D0;
}
.rc-recovery > .rc-rate-star-half:hover .rc-rate-star-first,
.rc-recovery > .rc-rate-star-full:hover .rc-rate-star-second {
	color: #63D4D0;
}

/*"heart": #fd949b,*/
/*"spirit": #c98fff,*/
/*"mind": #89bcf8,*/
/*"body": #63D4D0,*/
/*"recovery":#63D4D0*/